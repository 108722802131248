var pattern = Trianglify({
    variance: "0.3",
    cell_size: $(window).width() / Math.floor((Math.random() * 20) + 10),
    width: $(window).width(),
    height: $(window).height(),
    x_colors: 'Oranges'
});

$('#hero').prepend(pattern.canvas());
$('canvas').addClass('canvas-fixed');

var scroll_start = 300;
var startchange = $('.navbar');
var offset = startchange.offset();

$(document).scroll(function() {
    scroll_start = $(this).scrollTop();
    if(scroll_start > offset.top) {
        $('.navbar-smartris').css('background-color', 'rgba(0,0,0,0.7)');
        $('.navbar-brand').addClass('invert-logo');
    } else {
        $('.navbar-smartris').css('background-color', 'transparent');
        $('.navbar-brand').removeClass('invert-logo');
    }
});

function layoutAtendimento() {
    if($(this).width() > 996 && $(this).width() < 1100) {
        $('#second_best_feature').css('background', '#FFF url("public/assets/images/atendimento-screen.png") no-repeat center right');
        $('.sbf-screen-1').hide();
    } else if($(this).width() < 995) {
        $('#second_best_feature').css({
            'background': 'none',
            'background-color': '#FFF'
        });
        $('.sbf-screen-1').hide();
        $('.sbf-screen-2').hide();
    } else {
        $('#second_best_feature').css({
            'background': 'none',
            'background-color': '#FFF'
        });
        $('.sbf-screen-1').show();
        $('.sbf-screen-2').show();
    }
}

$(window).load(function() {
    layoutAtendimento();
});
$(window).resize(function() {
    layoutAtendimento();
});

$('a[href^="#"]').click(function(e) {
    e.preventDefault();
    $(window).stop(true).scrollTo(this.hash, {duration:1000, interrupt:true});
  });

$('.grid').masonry({
  columnWidth: '.grid-sizer',
  itemSelector: '.grid-item',
  percentPosition: true,
});

$('#signup-register-form').validate({
     submitHandler: function(form) {
         return;
     }
});

$("#company_phone").focus(function () {
        $(this).mask("(99) 9999-9999?9");
    });
    $("#company_phone").focusout(function () {
        var phone, element;
        element = $(this);
        element.unmask();
        phone = element.val().replace(/\D/g, '');
        if (phone.length > 10) {
            element.mask("(99) 99999-999?9");
        } else {
            element.mask("(99) 9999-9999?9");
        }
    });
